export const state = () => ({
    breadcrumb: [
        {
            text: 'پنل مدیریت نیوفایل',
            disabled: false,
            href: '/panel/dashboard',
        }
    ],
});

export const getters = {
    getBreadcrumb(store) {
        return store.breadcrumb || [
            {
                text: 'پنل مدیریت نیوفایل',
                disabled: false,
                href: '/panel/dashboard',
            }
        ];
    },
};

export const mutations = {
    setBreadcrumb(store, data) {
        store.breadcrumb = data;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
}


