import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";

Vue.use(device);


const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-dashboard/src/pages/Dashboard'),
        meta: {
            layout: 'panel',
            title: 'داشبورد',
            middleware: [AuthMiddleware],
        }
    }, {
        path: '/panel/dashboard',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-dashboard/src/pages/Dashboard'),
        meta: {
            layout: 'panel',
            title: 'داشبورد',
            middleware: [AuthMiddleware],
        }
    },
];


export default routes;