import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";

Vue.use(device);


const routes = [
    {
        path: '/panel/files/create',
        name: 'FileCreate',
        component: () => import('@Newfiling/module-file/src/pages/Create'),
        meta: {
            layout: 'panel',
            title: 'ثبت آگهی',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/divar/create/:id',
        name: 'DivarFileCreate',
        component: () => import('@Newfiling/module-file/src/pages/Divar/Create'),
        meta: {
            layout: 'panel',
            title: 'ثبت آگهی از دیوار',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/divar/list',
        name: 'DivarFileList',
        component: () => import('@Newfiling/module-file/src/pages/Divar/List'),
        meta: {
            layout: 'panel',
            title: 'لیست آگهی های دیوار',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/newfiling-list',
        name: 'FileListNewfiling',
        component: () => import('@Newfiling/module-file/src/pages/NewfilingList'),
        meta: {
            layout: 'panel',
            title: 'آگهی های نیوفایل',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/agency-list',
        name: 'FileListAgency',
        component: () => import('@Newfiling/module-file/src/pages/AgencyList'),
        meta: {
            layout: 'panel',
            title: 'آگهی های املاک',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/user-list',
        name: 'FileListUser',
        component: () => import('@Newfiling/module-file/src/pages/UserList'),
        meta: {
            layout: 'panel',
            title: 'آگهی های کاربران',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/search',
        name: 'FileSearch',
        component: () => import('@Newfiling/module-file/src/pages/Search'),
        meta: {
            layout: 'panel',
            title: 'جستجو آگهی ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/files/edit/:id',
        name: 'FileEdit',
        component: () => import('@Newfiling/module-file/src/pages/Edit'),
        meta: {
            layout: 'panel',
            title: 'ویرایش آکهی',
        }
    },
    {
        path: '/panel/files/statistics',
        name: 'FileStatistics',
        component: () => import('@Newfiling/module-file/src/pages/Statistics'),
        meta: {
            layout: 'panel',
            title: 'ویرایش آکهی',
        }
    },
];


export default routes;