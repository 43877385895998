import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";

Vue.use(device);


const routes = [
    {
        path: '/panel/messages/sent-sms',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-message/src/pages/SMSSent'),
        meta: {
            layout: 'panel',
            title: 'پیامک های ارسالی',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/messages/sent',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-message/src/pages/Sent'),
        meta: {
            layout: 'panel',
            title: 'پیام ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/messages/inbox',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-message/src/pages/Inbox'),
        meta: {
            layout: 'panel',
            title: 'پیام ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/messages/new-message',
        name: 'Dashboard',
        component: () => import('@Newfiling/module-message/src/pages/New'),
        meta: {
            layout: 'panel',
            title: 'پیام ها',
            middleware: [AuthMiddleware],
        }
    },
];


export default routes;