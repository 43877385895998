import device from "vue-device-detector"
import Vue from "vue";
Vue.use(device);


const routes = [
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@Newfiling/module-auth/src/pages/Login'),
        meta: {
            layout:  'empty',
            title: 'ورود به حساب کاربری'
        }
    },
    {
        path: '/panel/auth/verify-code',
        name: 'VerifyCode',
        component: () => import('@Newfiling/module-auth/src/pages/VerifyCode'),
        meta: {
            layout:  'panel',
            title: 'کد های ورود'
        }
    },
];


export default routes;