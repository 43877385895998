import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/demands/list',
        name: 'DemandsList',
        component: () => import('@Newfiling/module-demand/src/pages/List'),
        meta: {
            layout: 'panel',
            title: 'لیست درخواست ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/demands/create',
        name: 'DemandCreate',
        component: () => import('@Newfiling/module-demand/src/pages/Create'),
        meta: {
            layout:'panel',
            title: 'ثبت درخواست ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/demands/edit/:id',
        name: 'DemandEdit',
        component: () => import('@Newfiling/module-demand/src/pages/Edit'),
        meta: {
            layout: 'panel',
            title: 'ویرایش درخواست ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/demands/:id',
        name: 'DemandShow',
        component: () => import('@Newfiling/module-demand/src/pages/Details'),
        meta: {
            layout:  'panel',
            title: 'نمایش درخواست',
            middleware: [AuthMiddleware],
        }
    },
];


export default routes;