import Vue from "vue";
import VueRouter from "vue-router";
import middleware from "@grafikri/vue-middleware";

import authModule from "@Newfiling/module-auth/src/routes";
import dashboardModule from "@Newfiling/module-dashboard/src/routes";
import messageModule from "@Newfiling/module-message/src/routes";
import paymentModule from "@Newfiling/module-payment/src/routes";
import adminModule from "@Newfiling/module-admin/src/routes";
import locationModule from "@Newfiling/module-location/src/routes";
import fileModule from "@Newfiling/module-file/src/routes";
import demandModule from "@Newfiling/module-demand/src/routes";
import jobModule from "@Newfiling/module-job/src/routes";
import userModule from "@Newfiling/module-user/src/routes";
import commentModule from "@Newfiling/module-comment/src/routes";
import subscriptionModule from "@Newfiling/module-subscription/src/routes";

Vue.use(VueRouter);

const routes = [
  ...authModule,
  ...dashboardModule,
  ...messageModule,
  ...paymentModule,
  ...adminModule,
  ...locationModule,
  ...fileModule,
  ...demandModule,
  ...jobModule,
  ...userModule,
  ...commentModule,
  ...subscriptionModule,
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || process.env.VUE_APP_NAME;
  });

  window.scrollTo(0, 0);
});

router.beforeEach(middleware());

export default router;
