import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/users/list',
        name: 'PaymentList',
        component: () => import('@Newfiling/module-user/src/pages/List'),
        meta: {
            layout: 'panel',
            title: 'لیست کاربران',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/users/online',
        name: 'OnlineUser',
        component: () => import('@Newfiling/module-user/src/pages/OnlineUsers'),
        meta: {
            layout: 'panel',
            title: 'لیست کاربران انلاین',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/users/new',
        name: 'PaymentNew',
        component: () => import('@Newfiling/module-user/src/pages/Create'),
        meta: {
            layout: 'panel',
            title: 'ایجاد کاربر جدید',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/users/edit/:id',
        name: 'PaymentNew',
        component: () => import('@Newfiling/module-user/src/pages/Edit'),
        meta: {
            layout: 'panel',
            title: 'ویرایش کاربر',
            middleware: [AuthMiddleware],
        }
    },

];


export default routes;