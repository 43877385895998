import { v4 as uuidv4 } from 'uuid';


export const state = () => ({
    user: {
        token: null,
        mobile: "",
        name: "",
        permissions: '',
    },
    deviceId: null,
});

export const getters = {
    getUser(store) {
        return store.user;
    },

    getUserName(store) {
        return store.user.name;
    },

    isUserLogin(store) {
        return Boolean(store.user.token);
    },

    getToken(store) {
        return String(store.user.token)
    },

    getPermissions(store) {
        return String(store.user.permissions)
    },
    getDeviceId(store) {
        if (!store.deviceId) {
            return uuidv4();
        }
        return store.deviceId;
    },
};

export const mutations = {
    setUser(store, data) {
        store.user = {
            ...store.user,
            ...data,
        }
    },

    resetUser(store) {
        store.user = {
            ...store.user,
            token: null,
            mobile: "",
            name: "",
        }
    },

    setPermissions(store, data = []) {
        store.user = {
            ...store.user,
            permissions: data,
        }
    },
    setDeviceId(store) {
        if (!store.deviceId) {
            store.deviceId = uuidv4();
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}


