import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fa from 'vuetify/lib/locale/fa';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        dark:false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                main: '#2196f3',
                primary: '#2196f3',
                secondary: '#3f51b5',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
            dark: {
                main: '#2196f3',
            },
        },
    },
    lang: {
        locales: {fa},
        current: 'fa',
    },
    icons: {
        iconfont: 'md',
    },
});
