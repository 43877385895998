import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/locations/list',
        name: 'PaymentList',
        component: () => import('@Newfiling/module-location/src/pages/List'),
        meta: {
            layout: 'panel',
            title: 'لیست محله ها',
            middleware: [AuthMiddleware],
        }
    },
];


export default routes;