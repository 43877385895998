import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/jobs/list',
        name: 'JobsList',
        component: () => import('@Newfiling/module-job/src/pages/List'),
        meta: {
            layout: 'panel',
            title: 'لیست آگهی های کاریابی',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/jobs/edit/:id',
        name: 'JobEdit',
        component: () => import('@Newfiling/module-job/src/pages/Edit'),
        meta: {
            layout: 'panel',
            title: 'ویرایش آگهی های کاریابی',
            middleware: [AuthMiddleware],
        }
    },
];


export default routes;