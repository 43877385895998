import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/payments/list',
        name: 'PaymentList',
        component: () => import('@Newfiling/module-payment/src/pages/List'),
        meta: {
            layout: 'panel',
            title: 'لیست تراکنش ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/payments/new',
        name: 'PaymentNew',
        component: () => import('@Newfiling/module-payment/src/pages/Create'),
        meta: {
            layout: 'panel',
            title: 'ایجاد تراکنش جدید',
            middleware: [AuthMiddleware],
        }
    },

];


export default routes;