import device from "vue-device-detector"
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";
Vue.use(device);


const routes = [
    {
        path: '/panel/comments/file',
        name: 'CommentsFile',
        component: () => import('@Newfiling/module-comment/src/pages/FileComments'),
        meta: {
            layout: 'panel',
            title: 'لیست گزارشات فایل ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/comments/application',
        name: 'CommentsApplication',
        component: () => import('@Newfiling/module-comment/src/pages/ApplicationComments'),
        meta: {
            layout: 'panel',
            title: 'لیست گزارشات درخواست ها',
            middleware: [AuthMiddleware],
        }
    },
    {
        path: '/panel/comments/job',
        name: 'CommentsJob',
        component: () => import('@Newfiling/module-comment/src/pages/JobComments'),
        meta: {
            layout: 'panel',
            title: 'لیست گزارشات کاریابی ها',
            middleware: [AuthMiddleware],
        }
    },

];


export default routes;