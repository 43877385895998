import device from "vue-device-detector";
import Vue from "vue";
import {AuthMiddleware} from "@Newfiling/middleware";

Vue.use(device);

const routes = [
    {
        path: "/panel/prices/list",
        name: "PriceList",
        component: () => import("@Newfiling/module-subscription/src/pages/List"),
        meta: {
            layout: "panel",
            title: "لیست اشتراک ها",
            middleware: [AuthMiddleware],
        },
    },
    {
        path: "/panel/subscriptions/list",
        name: "subscriptionList",
        component: () =>
            import("@Newfiling/module-subscription/src/pages/Subscription/List"),
        meta: {
            layout: "panel",
            title: "لیست مشترکین",
            middleware: [AuthMiddleware],
        },
    },
    {
        path: "/panel/subscriptions/edit/:id",
        name: "subscriptionEdit",
        component: () =>
            import("@Newfiling/module-subscription/src/pages/Subscription/Edit"),
        meta: {
            layout: "panel",
            title: "ویرایش مشترکین",
            middleware: [AuthMiddleware],
        },
    },
    {
        path: "/panel/subscriptions/create",
        name: "subscriptionCreate",
        component: () =>
            import("@Newfiling/module-subscription/src/pages/Subscription/Create"),
        meta: {
            layout: "panel",
            title: "افزودن مشترکین",
            middleware: [AuthMiddleware],
        },
    },
    {
        path: "/panel/subscriptions/chart",
        name: "subscriptionChart",
        component: () =>
            import("@Newfiling/module-subscription/src/pages/Subscription/Chart"),
        meta: {
            layout: "panel",
            title: "آمار مشترکین",
            middleware: [AuthMiddleware],
        },
    },
    {
        path: "/panel/subscriptions/history/:mobile",
        name: "subscriptionHistory",
        component: () =>
            import("@Newfiling/module-subscription/src/pages/Subscription/History"),
        meta: {
            layout: "panel",
            title: "تاریخچه اشتراک ",
            middleware: [AuthMiddleware],
        },
    },
];

export default routes;
